import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Landing from "./pages/landing/landing.component";
import Auth from "./pages/auth/auth.component";
import Home from "./pages/home/home.component";

import PreLoader from "./components/preloader/preloader.component";

function App() {
  return (
    <>
      <PreLoader />
      <div className="App"></div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/auth/:action" element={<Auth />} />
          <Route path="/home/:section" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
