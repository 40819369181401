import React from "react";

import "./drawer-item.styles.scss";

const DrawerItem = ({ title, value, icon, section }) => {
  return (
    <div className={value === section ? "drawer-item-selected" : "drawer-item"}>
      <div className="icon">{icon}</div>
      <div className="title">{title}</div>
    </div>
  );
};

export default DrawerItem;
