import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Button } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./landing.styles.scss";

const Landing = () => {
  let navigate = useNavigate();

  const [persistentUserData, setPersistentUserData] = useState(null);

  useEffect(() => {
    setPersistentUserData(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <div className="landing">
      {/* Top Section */}
      <AppBar elevation={0} position="static" className="appbar">
        <Toolbar variant="dense" className="toolbar">
          <img
            alt="logo"
            className="logo"
            src={process.env.PUBLIC_URL + "/assets/logo-short.png"}
          />
          <Link className="selected">
            <Button className="btn">Home</Button>
          </Link>
          <Link className="link">
            <Button className="btn">Blogs</Button>
          </Link>
          <Link className="link">
            <Button className="btn">Developers</Button>
          </Link>
          <Link className="link">
            <Button className="btn">Contact US</Button>
          </Link>
        </Toolbar>
      </AppBar>
      {/* Middle Section */}
      <div className="content">
        <img
          alt="home"
          className="home1"
          src={process.env.PUBLIC_URL + "/assets/logo.png"}
        />
        <div className="tagline">Join the Conversation, Anytime, Anywhere</div>
        <Link
          className="action"
          to={
            persistentUserData === null ? "/auth/register" : "/home/dashboard"
          }
        >
          <div className="text">
            {typeof persistentUserData === "undefined" ||
            persistentUserData === null
              ? "GET STARTED"
              : `Continue as ${persistentUserData.name.toUpperCase()}`}
          </div>
          <ArrowForwardIos className="icon" />
        </Link>
        {typeof persistentUserData === "undefined" ||
        persistentUserData === null ? (
          ""
        ) : (
          <div className="alt-option">
            Continue to{" "}
            <span
              className="alt-link"
              onClick={() => {
                localStorage.removeItem("user");
                navigate("/auth/login");
              }}
            >
              Login
            </span>
          </div>
        )}
      </div>
      {/* Bottom Section */}
      <div className="footer">
        <a
          className="copyright"
          href="https://whoisrishav.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="wir"
            alt="wir"
            src={process.env.PUBLIC_URL + "/assets/wir.png"}
          />
          <div>
            <strong>WhoIsRishav</strong> | All rights reserved
          </div>
        </a>
        <div className="options">
          <Link className="link-cover">
            <div className="option-item">Privacy Policy</div>
          </Link>
          <Link className="link-cover">
            <div className="option-item">Terms of Use</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Landing;
