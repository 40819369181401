import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import PasswordValidator from "password-validator";
import axios from "axios";

import { toastStyle, generalToastStyle } from "../../utils/toast.styles";
import "./auth.styles.scss";

let theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 13,
  },
  palette: {
    primary: {
      main: "#ed6464",
    },
  },
});

const Auth = () => {
  const navigate = useNavigate();

  const { action } = useParams();
  // const action = "verify";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showRegisteringLoader, setShowRegisteringLoader] = useState(false);
  const [showVerificationLoader, setShowVerificationLoader] = useState(false);
  const [showLoginLoader, setShowLoginLoader] = useState(false);

  const register = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();

    if (name === "") {
      toast.warn("Name is required!", toastStyle);
    } else if (email === "") {
      toast.warn("Email is required!", toastStyle);
    } else if (password === "") {
      toast.warn("Password is required!", toastStyle);
    } else if (confirmPassword === "") {
      toast.warn("Confirm Password is required!", toastStyle);
    } else if (!validator.isEmail(email)) {
      toast.error("Invalid Email!", toastStyle);
    } else if (!passwordSchema.validate(password)) {
      toast.error(
        "Password should be atleadt 6 characters and should be containing atleast 1 uppercase, 1 lowercase and 1 digit and should not have any spaces",
        toastStyle
      );
    } else if (password !== confirmPassword) {
      toast.error(
        "Password and Confirm Password should be the same!",
        toastStyle
      );
    } else {
      setShowRegisteringLoader(true);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);

      axios
        .post(
          "https://discussify.app/backend/api/user/register.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setShowRegisteringLoader(false);
          toast(res.data.message, generalToastStyle);
          navigate("/auth/verify");
        })
        .catch((err) => {
          setShowRegisteringLoader(false);
          toast.error(err.response.data.message, toastStyle);
        });
    }
  };

  const verificationStatus = () => {
    setShowVerificationLoader(true);
    axios
      .get(
        `https://discussify.app/backend/api/user/verification_status.php?email=${email}`
      )
      .then((res) => {
        setShowVerificationLoader(false);
        if (res.data.success === 0) {
          toast.error(res.data.message, toastStyle);
        } else if (res.data.success === 1) {
          if (res.data.info["email_verified"] === "1") {
            toast("Email Verified!", generalToastStyle);
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(res.data.info));
            navigate("/home/dashboard");
          } else toast.warn("Email not yet verified!", toast);
        }
      })
      .catch((err) => {
        setShowVerificationLoader(false);
        toast.error(err.response.data.message, toastStyle);
      });
  };

  const login = () => {
    if (email === "") {
      toast.warn("Email is required!", toastStyle);
    } else if (password === "") {
      toast.warn("Password is required!", toastStyle);
    } else if (!validator.isEmail(email)) {
      toast.error("Invalid Email!", toastStyle);
    } else {
      setShowLoginLoader(true);

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      axios
        .post("https://discussify.app/backend/api/user/login.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setShowLoginLoader(false);
          toast("Login Successful!", generalToastStyle);
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(res.data.user));
          navigate("/home/dashboard");
        })
        .catch((err) => {
          setShowLoginLoader(false);
          toast.error(err.response.data.message, toastStyle);
        });
    }
  };

  return (
    <div className="auth">
      <ToastContainer />
      {/* Top Section */}
      <AppBar elevation={0} position="static" className="appbar">
        <Toolbar variant="dense" className="toolbar">
          <img
            alt="logo"
            className="logo"
            src={process.env.PUBLIC_URL + "/assets/logo-short.png"}
          />
          <Link className="selected">
            <Button className="btn">Home</Button>
          </Link>
          <Link className="link">
            <Button className="btn">Blogs</Button>
          </Link>
          <Link className="link">
            <Button className="btn">Developers</Button>
          </Link>
          <Link className="link">
            <Button className="btn">Contact US</Button>
          </Link>
        </Toolbar>
      </AppBar>
      {/* Middle Section */}
      <Grid className="content" container spacing={0}>
        {/* Left Section */}
        <Grid item xs={4} className="info">
          <img
            alt="home"
            className="home1"
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
          />
        </Grid>
        {/* Right Section */}
        {action === "register" ? (
          <Grid item xs={8} className="form">
            <div className="title">Create an Account</div>
            <div>Lorem Ipsum Lorem Ipsum</div>
            <ThemeProvider theme={theme}>
              <TextField
                className="field"
                size="small"
                label="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <TextField
                className="field"
                size="small"
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                className="field"
                size="small"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className="field"
                size="small"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ThemeProvider>
            <Button
              className="primary-btn"
              onClick={register}
              disabled={showRegisteringLoader}
            >
              {showRegisteringLoader ? (
                <div className="loader">
                  <CircularProgress
                    style={{
                      color: "#ed6464",
                      marginRight: "10px",
                    }}
                    size="1.2rem"
                  />{" "}
                  Registering
                </div>
              ) : (
                "Register"
              )}
            </Button>
            <div className="alt-text">
              Already have an account ?{" "}
              <Link className="alt-link" to="/auth/login">
                Log In
              </Link>
            </div>
          </Grid>
        ) : action === "verify" ? (
          <Grid item xs={8} className="form">
            <div className="title">Verify Email</div>
            <div>A hearty welcome to Discussify Family!</div>
            <div className="message">
              We have sent an email to the registered email,
              <br /> please verify the email and head back here to start
              exploring DISCUSSIFY
            </div>
            <Button
              className="primary-btn"
              onClick={verificationStatus}
              disabled={showVerificationLoader}
            >
              {showVerificationLoader ? (
                <div className="loader">
                  <CircularProgress
                    style={{
                      color: "#ed6464",
                      marginRight: "10px",
                    }}
                    size="1.2rem"
                  />{" "}
                  Checking Status
                </div>
              ) : (
                "Check Status"
              )}
            </Button>
            <div className="alt-text">
              Already have an account ?{" "}
              <Link className="alt-link" to="/auth/login">
                Log In
              </Link>
            </div>
          </Grid>
        ) : (
          <Grid item xs={8} className="form">
            <div className="title">Sign In</div>
            <div>A hearty welcome to Discussify Family!</div>
            <ThemeProvider theme={theme}>
              <TextField
                className="field"
                size="small"
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                className="field"
                size="small"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ThemeProvider>
            <Button
              className="primary-btn"
              onClick={login}
              disabled={showLoginLoader}
            >
              {showLoginLoader ? (
                <div className="loader">
                  <CircularProgress
                    style={{
                      color: "#ed6464",
                      marginRight: "10px",
                    }}
                    size="1.2rem"
                  />{" "}
                  Signing In
                </div>
              ) : (
                "Sign In"
              )}
            </Button>
            <div className="alt-text">
              Don't have an account ?{" "}
              <Link className="alt-link" to="/auth/register">
                Create New
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      {/* Bottom Section */}
      <div className="footer">
        <a
          className="copyright"
          href="https://whoisrishav.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="wir"
            alt="wir"
            src={process.env.PUBLIC_URL + "/assets/wir.png"}
          />
          <div>
            <strong>WhoIsRishav</strong> | All rights reserved
          </div>
        </a>
        <div className="options">
          <Link className="link-cover">
            <div className="option-item">Privacy Policy</div>
          </Link>
          <Link className="link-cover">
            <div className="option-item">Terms of Use</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Auth;
