import React from "react";
import { useParams } from "react-router-dom";
import { Dashboard, AppRegistration, Tune } from "@mui/icons-material";
import { Link } from "react-router-dom";

import "./home.styles.scss";

import DrawerItem from "../../components/drawer-item/drawer-item.component";

const Home = () => {
  let { section } = useParams();

  return (
    <div className="home">
      <div className="drawer">
        <img
          alt="logo"
          className="logo"
          src={process.env.PUBLIC_URL + "/assets/logo.png"}
        />
        <div className="content">
          <img
            alt="profile"
            className="profile"
            src={process.env.PUBLIC_URL + "/assets/wir.png"}
          />
          <div className="name">Rishav Kumar</div>
          <div className="edit-option">Edit</div>
          <DrawerItem
            title="Dashboard"
            value="dashboard"
            icon={<Dashboard />}
            section={section}
          />
          <DrawerItem
            title="Integration"
            value="integration"
            icon={<AppRegistration />}
            section={section}
          />
          <DrawerItem
            title="Settings"
            value="settings"
            icon={<Tune />}
            section={section}
          />
        </div>
        <div className="foot">
          <Link className="legacy-link">Privacy Policy</Link>
          <div className="dot" />
          <Link className="legacy-link">Terms of Use</Link>
        </div>
      </div>
      <div className="divider" />
      <div className="component"></div>
    </div>
  );
};

export default Home;
